import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '@/components/Typography/Title';
import {
  useResendSignatoryRequestMutation,
  useRejectSignatoryRequestMutation,
  useVerifySignatoryMutation,
} from '@/api/documentsApi';
import { showSuccessToast } from '@/utils/toast';
import { useAppSelector } from '@/utils/hooks';
import { Spinner } from '@/components/Spinner';
import { DocumentSinglePartyPhoto } from '@/components/SingleParty/SinglePartyPhoto';
import { getConvertedDateTimestamp } from '@/utils/dates';
import { TableCellPartyStatus } from '@/components/TableCells/TableCells';
import { PARTY_APPROVAL_STATUSES } from '@/constants/partyApprovalStatuses';
import { Button } from '@/components/Button';
import { Document, Signatory } from '@/types/document';
import { UserRole } from '@/types/user';

type SingleDocumentDetailsParams = {
  activeDocument: Document;
  refetch: () => any;
};

const SingleDocumentSignatures = ({
  activeDocument,
  refetch,
}: SingleDocumentDetailsParams) => {
  const { t } = useTranslation();

  const isAdmin = useAppSelector(state => state.user.profile?.role === UserRole.Admin);

  const [
    resendSignatoryRequest,
    { isSuccess: isResentSuccessfully, isLoading: isResendLoading },
  ] = useResendSignatoryRequestMutation();

  const [verifySignatory, { isSuccess: isVerified, isLoading: isVerifyLoading }] =
    useVerifySignatoryMutation();

  const [
    rejectSignatoryRequest,
    { isSuccess: isRejectSuccessfully, isLoading: isRejectLoading },
  ] = useRejectSignatoryRequestMutation();

  const isHandingAction = isResendLoading || isVerifyLoading || isRejectLoading;

  const handleVerify = (party: Signatory) => {
    verifySignatory({
      documentId: activeDocument.id,
      body: { email: party.email },
    });
  };

  const handleSendNewRequest = (party: Signatory) => {
    resendSignatoryRequest({
      documentId: activeDocument.id,
      body: { email: party.email },
    });
  };

  const handleRejectRequest = (party: Signatory) => {
    rejectSignatoryRequest({
      documentId: activeDocument.id,
      body: { email: party.email },
    });
  };

  useEffect(() => {
    if (isResentSuccessfully) {
      showSuccessToast('New request was sent successfully');
      refetch();
    }
  }, [isResentSuccessfully]);

  useEffect(() => {
    if (isVerified) {
      showSuccessToast(t('SingleDocumentDetails.verifiedPartySuccess'));
      refetch();
    }
  }, [isVerified]);

  useEffect(() => {
    if (isRejectSuccessfully) {
      showSuccessToast(t('SingleDocumentDetails.rejectedPartySuccess'));
      refetch();
    }
  }, [isRejectSuccessfully]);

  return (
    <div className="bg-white-primary flex flex-1 flex-col">
      <Title level="h4" className="content-layout background_page_content">
        {t('SingleDocumentDetails.labels.parties')}
      </Title>
      <div className="bg-white-primary h-full max-md:px-5 2xl:min-h-[750px]">
        {isHandingAction && <Spinner fullScreen size="huge" />}
        {activeDocument.signatories.map((party, idx) => (
          <div
            className={clsx(
              'flex justify-between items-start py-[40px]',
              '2xl:px-7.5 3xl:px-10 max-xl:px-7.5',
              'text_primary border-b-3 border-b-white-secondary relative bg-white-primary',
              'max-sm:flex-col max-sm:px-0',
              'print:flex-col print:px-0',
              'max-2xl:px-10'
            )}
            key={idx}
          >
            <div
              className={clsx(
                'w-[150px] h-[150px] self-center background_secondary',
                'max-sm:self-start max-sm:w-[107px] max-sm:mb-4',
                'print:self-start print:mb-4',
                'relative min-w-[160px]'
              )}
            >
              {party.photo && (
                <DocumentSinglePartyPhoto
                  documentId={activeDocument.id}
                  email={party.email}
                  signatoryName={`${party.name} ${party.surname}`}
                />
              )}
            </div>

            <div
              className={clsx(
                'max-2xl:ml-[40px] 3xl:ml-[40px] 2xl:ml-[20px] mr-auto',
                'max-sm:w-full max-sm:m-0',
                'print:w-full print:m-0'
              )}
            >
              <h5
                className={clsx('party_name_title', 'mb-[15px] break-all max-w-[250px]')}
              >
                {party.name} {party.surname}
              </h5>
              <div
                className={clsx(
                  '3xl:max-w-fit w-full mb-[20px] background_secondary',
                  'py-[7px] px-[10px] 2xl:max-w-[250px]',
                  'overflow-hidden text-ellipsis',
                  'max-sm:w-full max-sm:truncate',
                  'print:w-full print:truncate'
                )}
              >
                <span className="text_primary text-15px">{party.email}</span>
              </div>
              <div className="flex text-15px">
                <div className="font-bold leading-7 mr-[6px]">
                  <p>{t('PartyApproval.requested')}:</p>
                  <p>{t('PartyApproval.verified')}:</p>
                </div>
                <div className="leading-7">
                  <p>{getConvertedDateTimestamp(party.requestedAt)}</p>
                  {party.signedAt ? (
                    <p>{getConvertedDateTimestamp(party.signedAt)}</p>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
            <div
              className={clsx(
                'flex flex-col items-end min-w-[140px] right-0',
                'max-sm:top-8 max-sm:absolute',
                'print-hide'
              )}
            >
              <div
                className={clsx(
                  'flex items-center mb-[21px] mt-[6px] text-right',
                  'max-sm:mb-[17px]'
                )}
              >
                <TableCellPartyStatus status={party.status} isExtended />
              </div>
              {!isAdmin && party.status !== PARTY_APPROVAL_STATUSES.Approved && (
                <div className="flex justify-between flex-col font-bold">
                  {party.status === PARTY_APPROVAL_STATUSES.Signed ? (
                    <>
                      <Button
                        type="button"
                        className={clsx(
                          'button_smaller_caption',
                          '!py-[8px] w-[135px]',
                          'max-sm:!py-[3px] max-sm:w-[94px] 2xl:w-[115px] 3xl:w-[135px]'
                        )}
                        onClick={() => handleVerify(party)}
                      >
                        {t('PartyApproval.verify')}
                      </Button>

                      <div className="my-[9px] text-center max-sm:my-0 heading-7">or</div>
                      <Button
                        type="button"
                        className={clsx(
                          'button_smaller_caption',
                          '!py-[8px] w-[135px] !px-[11px]',
                          'max-sm:!py-[3px] max-sm:w-[94px] 2xl:w-[115px] 3xl:w-[135px]'
                        )}
                        onClick={() => handleRejectRequest(party)}
                      >
                        {t('PartyApproval.reject')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="button"
                      className={clsx(
                        'button_smaller_caption',
                        '!py-[8px] w-[135px] !px-[11px]',
                        'max-sm:!py-[3px] max-sm:w-[94px] 2xl:w-[115px] 3xl:w-[135px]'
                      )}
                      onClick={() => handleSendNewRequest(party)}
                    >
                      {t('PartyApproval.newRequest')}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleDocumentSignatures;
