import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Button';
import { Title } from '@/components/Typography/Title';
import { ICard } from '@/constants/cardlinks';

interface IProps {
  data: ICard;
}

const CardLink = ({ data }: IProps): JSX.Element => {
  const navigate = useNavigate();
  const { title, header, text, caption, url } = data;

  const handleClick = () => {
    navigate(url);
  };
  return (
    <div className={clsx('bg-white-primary w-full flex flex-col')}>
      <Title
        level="h2"
        className={clsx(
          'px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10',
          'background_secondary whitespace-nowrap'
        )}
      >
        {title}
      </Title>
      <div className={clsx('w-full px-[40px] pt-[30px] pb-[50px]')}>
        <div className="cardlink_title pb-[10px]">{header || <br />}</div>
        <div className="font_primary_base">{text}</div>
      </div>
      <Button
        onClick={handleClick}
        className="w-full flex flex-wrap whitespace-nowrap mt-auto"
      >
        {caption}
      </Button>
    </div>
  );
};

export default CardLink;
