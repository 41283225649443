import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { Button } from '@/components/Button';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import { DetailsBlock } from '@/components/DetailsBlock/DetailsBlock';
import { DocumentViewer } from '@/components/DocumentViewer';
import { getConvertedDate, getConvertedDateTimestamp } from '@/utils/dates';
import { Title } from '@/components/Typography/Title';
import ArrowIcon from '@/assets/icons/back.svg';
import DownloadIcon from '@/assets/icons/download.svg';
import ShareIcon from '@/assets/icons/share.svg';
import PrintIcon from '@/assets/icons/print.svg';
import { isPDFTronFile } from '@/constants/files';
import { useGetDocumentQuery } from '@/api/documentsApi';
import { HistoryTree } from '@/components/HistoryTree';
import { ShareViewModal } from '@/components/Modal/ShareViewModal';
import { PrintModal } from '@/components/Modal/PrintModal';
import { Spinner } from '@/components/Spinner';
import { DocumentParties } from '@/components/DocumentParties';
import { useAppSelector } from '@/utils/hooks';
import SingleDocumentSignatures from '@/pages/documents/SingleDocumentSignatures';
import DocumentsTransfer from '@/pages/admin/DocumentsTransfer';
import { documentToListItem } from '@/utils/documents';
import { scrollToDocumentTop, scrollToTop } from '@/utils/scroll';
import { Document, DocumentListItem } from '@/types/document';
import { UserRole } from '@/types/user';
import { ROUTES } from '@/constants/routes';

type SingleDocumentDetailsParams = {
  documentId: string;
};

const SingleDocumentDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { documentId: requestDocumentId } = useParams() as SingleDocumentDetailsParams;
  const [searchParams, setSearchParams] = useSearchParams();

  if (!requestDocumentId) {
    return null;
  }

  const documentId = searchParams.get('active') ?? requestDocumentId;
  const showSignatories = searchParams.get('signatories') === 'true';

  const isAdmin = useAppSelector(
    appState => appState.user.profile?.role === UserRole.Admin
  );

  const viewerRef = useRef<{ print: () => void; download: () => void }>(null);
  const historyRef = useRef<HTMLDivElement | null>(null);
  const documentRef = useRef<HTMLDivElement | null>(null);

  const printSummary = useReactToPrint({ content: () => historyRef.current });
  const printDocument = useReactToPrint({ content: () => documentRef.current });

  const {
    data: documentDetails,
    isLoading,
    isError,
    refetch,
  } = useGetDocumentQuery({
    documentId: requestDocumentId,
  });

  useEffect(() => {
    if (isError) {
      navigate(-1);
    }
  }, [isError]);

  const [activeDocument, setActiveDocument] = useState<Document | undefined>(undefined);
  const [isTransferMode, setIsTransferMode] = useState<boolean>(false);
  const [documentPath, setDocumentPath] = useState<string>('');

  const history = useMemo(() => {
    if (!documentDetails?.history) return [];
    return [
      ...documentDetails.history.previousLinkedDocuments,
      documentDetails,
      ...documentDetails.history.nextLinkedDocuments,
    ];
  }, [documentDetails]);

  const fileType = useMemo(
    () => activeDocument?.name.split('.').pop()?.toLowerCase() || '',
    [activeDocument]
  );

  useEffect(() => {
    if (!history.length) {
      setActiveDocument(documentDetails);
    } else {
      const document = history.find(doc => doc.id === documentId);
      if (!document) return;
      setActiveDocument(document);
    }
  }, [history]);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  window.onpopstate = () => {
    if (isShareModalOpen) setIsShareModalOpen(false);
    if (isPrintModalOpen) setIsPrintModalOpen(false);
  };

  const openShareModal = () => {
    setIsShareModalOpen(true);
    window.history.pushState({ modal: true }, '', window.location.href);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
    window.history.back();
  };

  const openPrintModal = () => {
    setIsPrintModalOpen(true);
    window.history.pushState({ printModal: true }, '', window.location.href);
  };

  const closePrintModal = () => {
    setIsPrintModalOpen(false);
    window.history.back();
  };

  const redirectBack = () => navigate(-1);

  const redirectToDocument = (id?: string) => {
    const document = history.find(doc => doc.id === id);
    if (!document) return;
    setActiveDocument(document);
    setSearchParams(prev => ({
      ...prev,
      active: document.id,
    }));
    scrollToDocumentTop();
  };

  const redirectToDocumentSignatures = (id?: string) => {
    const document = history.find(doc => doc.id === id);
    if (!document || !document.signatories.length) return;
    setActiveDocument(document);
    setSearchParams(prev => ({
      ...prev,
      active: document.id,
      signatories: true,
    }));
    scrollToDocumentTop();
  };

  const isPDFTron = useMemo(() => isPDFTronFile(fileType), [fileType]);

  const handlePrintDocument = () => {
    if (isPDFTron) {
      if (viewerRef.current) {
        viewerRef.current.print();
      }
    } else {
      printDocument();
    }
  };

  const handlePrintHistory = () => {
    printSummary();
  };

  const handleDownloadDocument = () => {
    if (isPDFTron) {
      if (viewerRef.current) {
        viewerRef.current.download();
      }
    } else if (documentPath && activeDocument) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = documentPath;
      a.download = activeDocument.name;
      a.click();
    }
  };

  const onDocumentTransfer = () => {
    setIsTransferMode(true);
    scrollToTop();
  };

  const onBackFromDocumentTransfer = () => {
    navigate(ROUTES.DOCUMENTS);
  };

  const transferringDocuments: DocumentListItem[] = useMemo(() => {
    if (!documentDetails) {
      return [];
    }
    return [documentToListItem(documentDetails)];
  }, [documentDetails]);

  if (isLoading) {
    return <Spinner size="large" className="mt-[77px]" />;
  }
  const onDocumentDownloaded = (str: string) => {
    if (str !== documentPath) setDocumentPath(str);
  };

  const newDocument = state?.fromCreate;

  const content = () => {
    return isTransferMode ? (
      <DocumentsTransfer
        documents={transferringDocuments}
        onClose={onBackFromDocumentTransfer}
        onComplete={onBackFromDocumentTransfer}
        backButtonTitle={t('SingleDocumentDetails.buttons.backFromTransfer')}
      />
    ) : (
      <>
        <div
          className={clsx(
            'grid gap-x-[3px]',
            newDocument && '2xl:grid-cols-[3fr,1fr] print:grid-cols-[1fr]',
            !newDocument && '2xl:grid-cols-[1fr,2fr,1fr] print:grid-cols-[1fr,1fr]',
            showSignatories && 'print:grid-cols-[1fr,1fr,1fr]',
            'max-lg:grid-cols-1 xl:grid-cols-1',
            'print:p-[2px]'
          )}
        >
          {!newDocument && (
            <div className="flex flex-col justify-between">
              <Title level="h4" className="content-layout">
                {t('SingleDocumentDetails.labels.history')}
              </Title>
              <div className="mb-auto">
                <HistoryTree
                  activeDocumentId={activeDocument?.id}
                  isActiveDocumentSignatories={showSignatories}
                  history={history}
                  redirectToDocument={redirectToDocument}
                  redirectToDocumentSignatures={redirectToDocumentSignatures}
                  printMode={isPrintModalOpen}
                />
              </div>
            </div>
          )}
          {activeDocument && showSignatories && (
            <SingleDocumentSignatures activeDocument={activeDocument} refetch={refetch} />
          )}
          {activeDocument && !showSignatories && (
            <div className="flex flex-col print-hide min-w-[200px]">
              <Title
                level="h4"
                className={clsx('content-layout', newDocument && 'bg-green-secondary')}
              >
                {t('SingleDocumentDetails.labels.preview')}
              </Title>
              <div className={clsx('bg-white-primary h-full', 'max-md:px-5 max-sm:px-1')}>
                <DocumentViewer
                  ref={viewerRef}
                  target="general"
                  documentId={activeDocument.id}
                  className="mt-0"
                  onDownloadCompleted={onDocumentDownloaded}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col justify-between min-w-[250px]">
            <Title
              level="h4"
              className={clsx('content-layout', newDocument && 'bg-green-secondary')}
            >
              {t('SingleDocumentDetails.labels.details')}
            </Title>
            <div
              className={clsx(
                'flex max-md:flex-col md:flex-row 2xl:flex-col',
                'h-full bg-white-primary items-stretch'
              )}
            >
              <div className="flex flex-1 flex-col">
                {newDocument && (
                  <DetailsBlock
                    label={t('UploadDocument.labels.documentName')}
                    text={activeDocument?.title}
                    textClassName="capitalize"
                  />
                )}
                <DetailsBlock
                  label={t('UploadDocument.labels.type')}
                  text={activeDocument?.type}
                  textClassName="capitalize"
                />
                <DetailsBlock
                  label={t('UploadDocument.labels.client')}
                  text={activeDocument?.client}
                />
                {isAdmin && (
                  <DetailsBlock
                    label={t('UploadDocument.labels.user')}
                    text={`
                    ${activeDocument?.user?.name} 
                    ${activeDocument?.user?.surname}`}
                    action={onDocumentTransfer}
                    actionLabel={t('SingleDocumentDetails.buttons.transfer')}
                  />
                )}
                <DetailsBlock
                  label={t('UploadDocument.labels.date')}
                  text={getConvertedDate(activeDocument?.agreementDate)}
                />
                <DetailsBlock
                  label={t('UploadDocument.labels.creationDate')}
                  text={getConvertedDateTimestamp(
                    activeDocument?.createdAt,
                    undefined,
                    true
                  )}
                />
                {newDocument && state?.linkedDocument && (
                  <DetailsBlock
                    label={t('UploadDocument.labels.linkedTo')}
                    text={state?.linkedDocument?.title}
                    textClassName="capitalize"
                  />
                )}
                <DetailsBlock label={t('SingleDocumentDetails.labels.status')}>
                  {activeDocument?.authenticatedAt ? (
                    <span className="text-green-primary capitalize">
                      {t('SingleDocumentDetails.nftStatus.active')}
                    </span>
                  ) : (
                    <span className="text-yellow-primary capitalize">
                      {t('SingleDocumentDetails.nftStatus.pending')}
                    </span>
                  )}
                </DetailsBlock>
              </div>
              {!showSignatories && (
                <div className="flex flex-1 flex-col justify-start pb-[32px]">
                  <p
                    className={clsx(
                      'input_label',
                      'bg-white-primary',
                      'py-3 px-25px md:px-7.5 xl:py-4 text-left block'
                    )}
                  >
                    {t('AddParties.partiesLabel')}
                  </p>
                  <DocumentParties
                    parties={activeDocument?.signatories}
                    onRedirectClick={() =>
                      redirectToDocumentSignatures(activeDocument?.id)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            'grid xl:grid-cols-4 gap-[3px] mt-[3px] print-hide',
            isAdmin ? 'grid-cols-2' : 'grid-cols-3'
          )}
        >
          <Button
            type="button"
            className="col-1 max-xl:order-4 max-xl:col-span-3"
            onClick={redirectBack}
          >
            <img src={ArrowIcon} alt="" className="h-[24px]" />
            <span className="ml-[40px] max-md:hidden xl:hidden 2xl:block">
              {t('SingleDocumentDetails.buttons.back')}
            </span>
          </Button>
          <Button
            type="button"
            className={clsx('col-2', isAdmin && 'xl:col-span-2')}
            onClick={handleDownloadDocument}
          >
            <img src={DownloadIcon} alt="" className="w-[28px]" />
            <span className="ml-[40px] max-md:hidden xl:hidden 2xl:block">
              {t('SingleDocumentDetails.buttons.download')}
            </span>
          </Button>
          {!isAdmin && (
            <Button type="button" className="col-3" onClick={openShareModal}>
              <img src={ShareIcon} alt="" className="w-[28px]" />
              <span className="ml-[40px] max-md:hidden xl:hidden 2xl:block">
                {t('SingleDocumentDetails.buttons.share')}
              </span>
            </Button>
          )}
          <Button type="button" className="col-4" onClick={openPrintModal}>
            <img src={PrintIcon} alt="" className="w-[28px]" />
            <span className="ml-[40px] max-md:hidden xl:hidden 2xl:block">
              {t('SingleDocumentDetails.buttons.print')}
            </span>
          </Button>
        </div>
        <ShareViewModal
          documentId={activeDocument?.id || documentId}
          isOpen={isShareModalOpen}
          onClose={closeShareModal}
        />
        <PrintModal
          onPrintHistory={handlePrintHistory}
          onPrintDocument={handlePrintDocument}
          isOpen={isPrintModalOpen}
          onClose={closePrintModal}
        />
      </>
    );
  };

  return (
    <>
      <ContentLayout
        title={newDocument ? t('DocumentPreview.title') : activeDocument?.title}
        ref={historyRef}
        className={clsx('print:m-10', newDocument && 'border-t-green-primary')}
      >
        {content()}
      </ContentLayout>
      {activeDocument && (
        <div className={clsx('h-0 print:h-fit overflow-hidden')} ref={documentRef}>
          <DocumentViewer target="general" documentId={activeDocument.id} printMode />
        </div>
      )}
    </>
  );
};

export default SingleDocumentDetails;
