import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '@/assets/images/logo-dashboard.svg';
import ArrowDownIcon from '@/assets/icons/arrow-down.svg';
import NotificationIcon from '@/assets/icons/notification.svg';
import { ROUTES } from '@/constants/routes';
import { useGetUserDataQuery } from '@/api/userApi';
import { acronym } from '@/utils/acronym';
import { DashboradMobileMenu } from '../DashboradMobileMenu';
import { Spinner } from '../Spinner';
import SearchInput from '@/components/SearchInput/SearchInput';

const DashboardHeader = () => {
  const [notificationNumber] = useState<number>(1);
  const { data: profileDetails, isLoading, refetch } = useGetUserDataQuery();

  const fullName = `${profileDetails?.name} ${profileDetails?.surname}`;

  // when user switches between accounts, info from one account can be
  // duplicated into another. To avoid this, here in header we refetch the data
  // and since header is being used everywhere on the dashboard, info updates
  // only if user switches from login screen to the dashboard

  useEffect(() => {
    refetch();
  }, []);

  return (
    <header
      className={clsx(
        'fixed w-[100%] flex items-center justify-between xl:px-15 py-[35px]',
        'bg-white-primary z-[99] max-sm:px-3 max-xl:px-10'
      )}
    >
      <NavLink to={ROUTES.HOME} className={clsx('relative block pr-[200px] max-sm:pr-2')}>
        <Logo className="w-[167px] h-auto max-sm:w-[219px]" />
      </NavLink>

      <SearchInput className="max-xl:hidden" />

      <div
        className={clsx(
          'relative pr-7.5 flex items-center',
          'after:w-[2px] after:absolute max-xl:hidden',
          ' after:h-[100%] after:right-0 after:top-0'
        )}
      >
        <button type="button" className="relative hidden">
          <img src={NotificationIcon} alt="notification" />
          {notificationNumber > 0 && (
            <span
              className={clsx(
                'absolute top-0 right-[-5px] text-white-primary text-15px flex',
                'bg-red-primary rounded-full w-[20px] h-[20px] items-center',
                'justify-center',
                notificationNumber > 99 && 'text-[12px]'
              )}
            >
              {notificationNumber > 99 ? '99+' : notificationNumber}
            </span>
          )}
        </button>
      </div>
      <div className="flex items-center ml-7.5 max-xl:hidden">
        {isLoading ? (
          <Spinner size="small" />
        ) : (
          <>
            <div
              className={clsx(
                'background_tetriary flex items-center justify-center',
                'rounded-full w-12.5 h-12.5 text-[20px] tracking-normal'
              )}
            >
              <span
                className={clsx(
                  'inline-block px-3 pt-[9px] pb-[5px] font-bold text_primary ',
                  'whitespace-nowrap uppercase'
                )}
              >
                {acronym(profileDetails?.name, profileDetails?.surname)}
              </span>
            </div>
            <h4
              className={clsx(
                'fullname_title',
                'ml-[15px] mr-0 max-w-[300px]',
                'overflow-hidden text-ellipsis break-all whitespace-nowrap'
              )}
            >
              {fullName}
            </h4>
            <img src={ArrowDownIcon} alt="arrow down" className="hidden" />
          </>
        )}
      </div>
      <DashboradMobileMenu />
    </header>
  );
};

export default DashboardHeader;
