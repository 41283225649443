import { FunctionComponent } from 'react';
import clsx from 'clsx';

import { ReactComponent as CheckIcon } from '@/assets/icons/check-round.svg';
import { ReactComponent as ExclamationIcon } from '@/assets/icons/exclamation-round.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';

export type ToastIconType = 'error' | 'success' | 'warning';

interface ToastProps {
  type?: ToastIconType;
  children?: React.ReactNode;
  onCloseClick?: () => void;
}

const iconMap: { [key in ToastIconType]: FunctionComponent } = {
  error: ExclamationIcon,
  warning: ExclamationIcon,
  success: CheckIcon,
};

const Toast = ({ type = 'success', children, onCloseClick }: ToastProps) => {
  const Icon = iconMap[type];

  return (
    <div
      className={clsx(
        'flex flex-row items-center p-[35px]',
        'min-w-[400px] max-sm:min-w-[300px]',
        {
          'bg-green-secondary text-green-primary': type === 'success',
          'bg-yellow-secondary text-yellow-primary': type === 'warning',
          'bg-red-tetriary text-red-error': type === 'error',
        }
      )}
    >
      <Icon />
      <span className="ml-5 flex-1 mr-2">{children}</span>
      <button type="button" onClick={onCloseClick} aria-label="Close">
        <CloseIcon />
      </button>
    </div>
  );
};
export default Toast;
