import toast from 'react-hot-toast';

import { Toast } from '@/components/Toast';
import i18n from '@/translations';

export const showErrorToast = (message?: string) =>
  toast.custom(t => (
    <Toast onCloseClick={() => toast.remove(t.id)} type="error">
      {message || `${i18n.t('Common.errors.somethingWentWrong')}`}
    </Toast>
  ));

export const showSuccessToast = (message: string) =>
  toast.custom(t => (
    <Toast onCloseClick={() => toast.remove(t.id)} type="success">
      {message}
    </Toast>
  ));
