import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TableCellPartyStatus } from '../TableCells/TableCells';
import { Button } from '../Button';
import { useAppSelector } from '@/utils/hooks';
import { Signatory } from '@/types/document';
import { UserRole } from '@/types/user';

interface DocumentPartiesProps {
  parties?: Signatory[];
  onRedirectClick?: () => void;
  isSimplified?: boolean;
}

const DocumentParties = ({
  parties,
  onRedirectClick,
  isSimplified,
}: DocumentPartiesProps): JSX.Element => {
  const { t } = useTranslation();

  const isAdmin = useAppSelector(state => state.user.profile?.role === UserRole.Admin);

  const buttonText = useMemo(() => {
    if (!parties) {
      return '';
    }

    if (isAdmin) {
      return t('SingleDocumentDetails.buttons.goToSignatures');
    }

    if (parties.every(party => party.status === 'approved')) {
      return t('SingleDocumentDetails.buttons.goToSignatures');
    }

    return t('SingleDocumentDetails.buttons.verifySignatures');
  }, [parties]);

  if (!parties || parties.length === 0) {
    return (
      <div className="px-25px md:px-7.5">
        <p className="content-layout !px-0 !pt-[16px]">
          {t('SingleDocumentDetails.emptyParties')}
        </p>
      </div>
    );
  }

  return (
    <>
      {parties.map(party => (
        <div key={party.id}>
          <div
            className="px-25px md:px-7.5 text_primary mb-[16px]"
            key={party.id}
          >
            <div className="flex items-center justify-between">
              <p
                className={clsx(
                  'font-bold capitalize overflow-hidden',
                  'text-ellipsis max-w-[250px]'
                )}
              >
                {party.name} {party.surname}
              </p>
              {!isSimplified && <TableCellPartyStatus status={party.status} />}
            </div>
            <p className="truncate">{party.email}</p>
          </div>
        </div>
      ))}
      {!isSimplified && (
        <div className="px-25px md:px-7.5">
          <Button
            type="button"
            className={clsx('button_caption !py-[5px] print-hide')}
            onClick={onRedirectClick}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </>
  );
};

export default DocumentParties;
