import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from '@/components/Button';
import DynamicListbox from '@/components/DynamicListbox/DynamicListbox';
import { SelectInput } from '@/components/SelectInput';
import { Switch } from '@/components/Switch';
import { TextInput } from '@/components/TextInput';
import { Title } from '@/components/Typography/Title';
import {
  CreateDocumentFormData,
  createDocumentFormSchema,
} from '@/forms/createDocumentForm';
import {
  useCreateDocumentMutation,
  useGetDocumentTypesQuery,
  useLazyGetUserDocumentsQuery,
} from '../../api/documentsApi';
import { AddPartyFormRow } from '@/components/AddPartyFormRow';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import DatePickerInput from '@/components/DatePicker/DatePicker';
import { DocumentPreviewModal } from '@/components/Modal/DocumentPreviewModal';
import { Spinner } from '@/components/Spinner';
import { Paragraph } from '@/components/Typography/Paragraph';
import { ROUTES } from '@/constants/routes';
import { constructRoute } from '@/utils/routes';
import { showErrorToast } from '@/utils/toast';
import { fileSizeLimit, fileTypes } from '@/constants/files';
import { FileInput } from '@/components/FileInput';
import { Required } from '@/components/Typography/Required';
import { useModal } from '@/utils/hooks';
import { DocumentListItem } from '@/types/document';

const defaultFormState: CreateDocumentFormData = {
  title: '',
  client: '',
  type: '',
  agreementDate: '',
  linkedDocument: null,
  isCopyDescription: false,
  signatories: [],
};

export const documentPartiesLimit = 10;
export const linkedDocumentsSortBy = 'title';
export const linkedDocumentsSortOrder = 'asc';

const CreateDocument = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [linkedDoc, setLinkedDoc] = useState<string | undefined>(undefined);

  const { isModalOpen, openModal, closeModal } = useModal();

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [createDocument, { isLoading, isSuccess, isError }] = useCreateDocumentMutation();
  const { data: documentTypes } = useGetDocumentTypesQuery();
  const [getUserDocuments, { isFetching: isFetchingDocuments }] =
    useLazyGetUserDocumentsQuery();

  const { handleSubmit, control, setValue, getValues, watch, resetField } = useForm({
    resolver: joiResolver(createDocumentFormSchema),
    defaultValues: defaultFormState,
  });
  const linkedDocSelected = watch('linkedDocument');

  const [linkedDocuments, setLinkedDocuments] = useState<DocumentListItem[]>([]);

  const handleChangeLinkedDoc = (str: string) => {
    setLinkedDoc(str);
    const selected = linkedDocuments.find(document => document.title === str);
    return !!selected;
  };

  useEffect(() => {
    if (linkedDoc === undefined) return;
    getUserDocuments({
      filters: {
        limit: 10,
        title: linkedDoc || undefined,
        sortBy: linkedDocumentsSortBy,
        sortOrder: linkedDocumentsSortOrder,
      },
    }).then(result => {
      setLinkedDocuments(result?.data?.data || []);
    });
  }, [linkedDoc]);

  const linkedDocumentOptions = useMemo(() => {
    return linkedDocuments.map(item => ({
      value: item.id,
      label: item.title,
    }));
  }, [linkedDocuments]);

  const documentTypesOptions = useMemo(() => {
    if (!documentTypes) return [];
    return documentTypes.map(documentType => ({
      value: documentType.type,
      label: documentType.type,
    }));
  }, [documentTypes]);

  useEffect(() => {
    if (isError) {
      showErrorToast();
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<CreateDocumentFormData> = async formData => {
    if (!selectedFile) {
      return;
    }

    const submitFormData = new FormData();
    submitFormData.append('file', selectedFile);
    submitFormData.set('title', formData.title);
    submitFormData.set('type', formData.type);
    submitFormData.set('client', formData.client);
    submitFormData.set('agreementDate', new Date(formData.agreementDate).toISOString());
    submitFormData.set('signatories', JSON.stringify(formData.signatories));

    let linkedDocument;
    if (formData.linkedDocument) {
      linkedDocument = linkedDocuments.find(
        document => document.id === formData.linkedDocument
      );
      submitFormData.set('linkedDocument', formData.linkedDocument);
    }

    const result = await createDocument(submitFormData);
    if ('data' in result) {
      if (result.data?.id) {
        navigate(constructRoute(ROUTES.DOCUMENT_DETAILS, result.data.id), {
          state: {
            fromCreate: true,
            linkedDocument,
          },
        });
      } else {
        showErrorToast(t('Common.errors.documentNotCreated'));
      }
    }
  };

  const handleChooseFile = () => inputFileRef?.current?.click();

  const changeHandler = (file: File) => setSelectedFile(file);

  const onResetFile = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
      setSelectedFile(null);
    }
  };

  const {
    fields: fieldsParty,
    append: appendParty,
    remove: removeParty,
  } = useFieldArray({
    control,
    name: 'signatories',
  });

  const onCopyFromLinked = useCallback(
    (value: boolean) => {
      if (!value) {
        setValue('title', '');
        setValue('type', '');
        setValue('client', '');
        setValue('agreementDate', '');

        return;
      }

      const [title, type, client, agreementDate, linkedDocument] = getValues([
        'title',
        'type',
        'client',
        'agreementDate',
        'linkedDocument',
      ]);
      if (!linkedDocument) return;

      // find linked document
      const doc = linkedDocuments.find(document => document.id === linkedDocument);
      if (!doc) return;

      // copy values
      if (!title) setValue('title', doc.title);
      if (!type) setValue('type', doc.type);
      if (!client) setValue('client', doc.client);
      if (!agreementDate) setValue('agreementDate', doc.agreementDate);
    },
    [linkedDocuments]
  );

  return (
    <ContentLayout title={t('Authenticate.title')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative flex items-center justify-between content-layout">
          <Title>{t('Authenticate.documentDescription')}</Title>
          <Required />
        </div>

        <div className="grid md:grid-cols-2 md:gap-x-[3px] grid-cols-1">
          <div>
            <TextInput
              label={t('Authenticate.labels.documentTitle')}
              name="title"
              control={control}
              isRequired
            />
            <SelectInput
              label={t('Authenticate.labels.documentType')}
              name="type"
              control={control}
              isRequired
              options={documentTypesOptions}
            />
            <DatePickerInput
              label={t('Authenticate.labels.documentDate')}
              name="agreementDate"
              control={control}
              isRequired
            />
          </div>
          <div>
            <TextInput
              label={t('Authenticate.labels.client')}
              name="client"
              control={control}
              isRequired
            />
            <DynamicListbox
              label={t('Authenticate.labels.linkToDoc')}
              placeholder={t('Authenticate.labels.linkedDocPlaceholder')}
              options={linkedDocumentOptions}
              name="linkedDocument"
              control={control}
              isLoading={isFetchingDocuments}
              onChangeHandler={handleChangeLinkedDoc}
              resetField={() => resetField('linkedDocument')}
              debounced
            />
            <div className="content-layout">
              <Switch
                name="isCopyDescription"
                text={t('Authenticate.labels.copyDescription')}
                control={control}
                disabled={!linkedDocSelected}
                customOnChanged={onCopyFromLinked}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between content-layout">
          <Title>{t('UploadDocument.newTitle')}</Title>
        </div>

        <div className="grid md:grid-cols-2 md:gap-x-[3px] grid-cols-1">
          <div
            className={clsx(
              'font_primary_base',
              'bg-white-primary border-l-3 border-white-primary',
              'px-25px md:px-7.5 xl:px-10 bg-white-primary outline-none'
            )}
          >
            <p className="truncate py-[35px] max-xl:py-[22px] max-md:py-[26px]">
              {selectedFile?.name || t('UploadDocument.noDocumentChosen')}
            </p>
            <FileInput
              accept={fileTypes.document.accept}
              limit={fileSizeLimit.document}
              extensions={fileTypes.document.extensions}
              innerRef={inputFileRef}
              onReset={onResetFile}
              onChange={changeHandler}
            />
          </div>
          {selectedFile ? (
            <div className="grid md:grid-cols-2 md:gap-x-[3px] gap-y-[3px] grid-cols-1">
              <Button
                type="button"
                onClick={openModal}
                className="w-full h-[96px] max-xl:h-auto"
              >
                {t('UploadDocument.previewDoc')}
              </Button>
              <Button
                className="w-full h-[96px] max-xl:h-auto"
                onClick={handleChooseFile}
              >
                {t('UploadDocument.chooseFile')}
              </Button>{' '}
            </div>
          ) : (
            <Button className="w-full h-[96px] max-xl:h-auto" onClick={handleChooseFile}>
              {t('UploadDocument.chooseFile')}
            </Button>
          )}
        </div>

        <div className="max-lg:order-1">
          <Paragraph className="text-center py-5">
            {t('UploadDocument.maxSize')}
          </Paragraph>
        </div>

        <div className="background_page_content flex flex-col">
          <AddPartyFormRow
            append={appendParty}
            remove={removeParty}
            control={control}
            fields={fieldsParty}
            arrayName="signatories"
            title={t('AddParties.addParties')}
            addButtonTitle={t('AddParties.addParty')}
            limit={documentPartiesLimit}
            limitError={t('AddParties.errors.partiesLimit', {
              count: documentPartiesLimit,
            })}
          />
          <p className="mb-[35px] px-12.5 text_primary">{t('AddParties.description')}</p>
        </div>

        <Button disabled={isLoading} isSubmit className="w-full">
          {t('UploadDocument.createNFT')}
        </Button>
        {selectedFile && isModalOpen && (
          <DocumentPreviewModal
            isOpen={isModalOpen}
            onClose={closeModal}
            file={selectedFile}
          />
        )}
        {isLoading && (
          <Spinner size="huge" message={t('Common.uploadingFile')} fullScreen />
        )}
      </form>
    </ContentLayout>
  );
};

export default CreateDocument;
